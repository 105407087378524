.navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 92%;
    background-color: #333;
    padding: 2% 4%;
    text-align: end;
    height: 10vh;
    align-items: center;
    display: flex;
    z-index: 99;
    color: goldenrod;
    flex-direction: column;
    justify-content: center;   
    z-index: 100;     
}

.navbar h2 {
    font-size: 50px;
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    margin-left: 10px;
}

.navbar ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.navbar ul li {
    display: inline-block;
    margin-right: 20px;
}

.navbar ul li:last-child {
    margin-right: 0;
}

.navbar ul li a {
    text-decoration: none;
    color: white;
    font-weight: bold;
    padding: 10px;
}

.menuBars {
    color: white;
    font-size: 30px;
}

.popover-menu {
    position: absolute;
    top: 65px;
    left: 0;
    background-color: goldenrod;
    z-index: 101;
    text-align: center;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
}

.popover-menu a {
    display: block;
    padding: 8px 12px;
    color: #333;
    text-decoration: none;
    border: 1px solid #333;
    margin: 10px;
    border-radius: 5px;
    font-weight: 900;
}

.popover-menu a:hover {
    background-color: #f4f4f4;
}

.navbar ul li a:hover{
    background-color: goldenrod;
    color: black !important;
}

.images {
    width: 55px;
}

.smallimages {
    width: 40px;
}

.titleNav, .mobileNav {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100vw;
    height: 10vh;
    justify-content: center;
}

.mobileNav {
    color: white;
    width: 100%;
    justify-content: space-between;
}

.mobileNav h2 {
    color: goldenrod;
    width: auto;
    font-family: "Lora", serif;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
    font-size: 25px;
}

.mobileNav a {
    text-decoration: none;
}

.selectedLink {
    background-color: goldenrod;
    color: black !important;
}

.blur {
    height: 90vh;
    width: 100vw;
    background-color: rgba(51, 51, 51, 0.7);
    position: fixed;
    top: 10vh;
    left: 0;
    z-index: 2;
}