.SignInContainer {
    width: 100vw;
    height: 90vh;
    display: grid;
    position: relative;
    text-align: center;
    color: white;
    align-items: center;
    align-content: center;
}

.SignInContainer button {
    background-color: transparent;
    border: 2px solid white;
    color: white;
    padding: 5px;
    margin: 20px;
}

.SignInContainer a {
    color: white;
    text-decoration: none;
}

.inputField {
    margin-top: 20px;
}