/* .menu-page {
    padding: 15vh 5vw 5vh 5vw;
} */

.menu-container {
    width: 100vw;
    position: absolute;
    left: 0;
    display: flex;
    background: goldenrod;
    flex-direction: column;
    align-items: center;
    margin-top: 15vh;
}

.food-container {
    display: flex;
    color: black;
    width: 65vw;
    justify-content: space-between;
    margin-bottom: 10vh;
}

.food-container h2, .sides h2, .desserts h2 {
    font-weight: 600;
    font-size: 55px;
    /* font-family: 'Bebas Neue', sans-serif; */
    font-family: "Vast Shadow", serif;
    font-family: "Tourney", sans-serif;
        font-optical-sizing: auto;
        font-style: normal;
        font-variation-settings:
            "wdth" 100;
    margin-bottom: 0;
    text-align: center;
}

.sides h2, .desserts h2 {
    margin-top: 0;
}

.food-container h4, .desserts h4, .sides h4 {
    font-size: 30px;
    font-weight: 900;
    padding: 0;
    margin-bottom: 5px;
    font-family: 'Bebas Neue', sans-serif;
}

.food-container p, .sides p, .desserts p {
    padding: 0;
    margin: 0;
    font-weight: 300;
}

.desserts {
    margin-bottom: 10vh;
}

.firstElement h4 {
    margin-top: 0;
}

.leftside, .rightside {
    width: 45%;
}

.BYO, .dressings h5 {
    margin: 0;
}

.BYO, .salads h2 {
    margin: 0;
}

.salads, .burgers {
    margin-top: 0;
}

.divider {
    width: 100%;
    height: 2px;
    background-color: black;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.menuNav {
    display: flex;
    height: 10vh;
    width: 50%;
    justify-content: space-evenly;
    align-items: center;
}

.menuNav h6 {
    width: auto;
    padding: 10px;
    text-align: center;
    font-family: 'Bebas Neue', sans-serif;
    font-size: 20px;
}

.menuNav h6:hover {
    background-color: rgb(55, 55, 55);
    color: goldenrod;
}

.selected {
    background-color: black;
    color: goldenrod;
}

.specials {
    justify-content: center;
    height: 70vh;
}

.happyHour {
    border: 2px solid black;
    padding: 10px;
    width: 100%;
    text-align: center;
    height: fit-content;
    background-color: goldenrod;
    border-radius: 10px;
}

.happyHour h2 {
    margin-top: 0;
}

.dressings, .burgerDescription, .sides p, .beerDiscription {
    text-align: center;
}

.firstDessert {
    margin-top: 0;
}

.beerDiscription h3 {
    margin-top: 0;
    margin-bottom: 0;
}

@media only screen and (max-width: 1000px) {
    .food-container {
        display: contents;
    }
    .leftside, .rightside {
        width: 80%;
        margin-bottom: 10vh;
    }
    .bufferDiv {
        height: 25vh;
    }
    .menu-container {
        /* height: 90vh; */
        margin-top: 5vh;
        overflow-y: auto;
        min-block-size: -webkit-fill-available;
    }
    .desserts, .sides {
        width: 80vw;
    }
    .desserts, .BYO,
    .salads h2 {
        margin-top: 10vh;
    }
    .divider {
        display: none;
    }
    .bottlespacer {
        margin-top: 5vh;
    }
    .cocktails {
        margin-top: 0;
    }
    .wine{
        margin-top: 10vh;
    }
    .specials {
        min-height: 90vh;
    }
}