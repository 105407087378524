.merch {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: goldenrod;
    padding-top: 15vh;
}

.apparel-container {
    height: 100vh;
}

.merch h2 {
    font-family: "Vast Shadow", serif;
    font-family: "Tourney", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-weight: 400;
    font-size: 40px;
    color: white;
}

.merch img {
    width: 35vw;
    height: 55vh
}

@media only screen and (max-width: 1000px) {
    .merch img {
        width: 90vw;
        height: 50vh
    }
    .merch{
        padding-top: 10vh;
    }
}

