.Homepage-Container {
    background-color: goldenrod;
    height: 100vh;
    overflow-y: auto;
}

.slideshow-container {
    margin-top: 5vh;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 80vh;
    margin-top: 15vh;
}

.mySlides {
    position: absolute;
    width: 100%;
    height: 80vh;
    top: 0;
    left: 100%;
    transition: left 1s ease;
}

.mySlides.active {
    left: 0;
}

.mySlides img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(40%);
}

.text {
    position: absolute;
    color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    font-size: 30px;
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

.eventsMain {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 15vh;
    margin-bottom: 15vh;
}

.eventsMain img, .eventsDiv {
    width: 40vw;
    height: 55vh;
}

.eventsDiv {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.eventsDiv h2, .happyHourMain h2 {
    font-family: "Vast Shadow", serif;
    font-family: "Tourney", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-weight: 600;
    margin: 0;
    font-size: 40px;
}

.happyHourMain h4, .eventsDiv h4 {
    font-family: 'Bebas Neue', sans-serif;
    font-size: 30px;
    margin: 2vh;
}

.mainlinkbtn {
    border: 2px solid black;
    background: transparent;
    color: black;
    padding: 10px;
    font-weight: 900;
}

.mainlinkbtn:hover {
    background-color: black;
    color: goldenrod;
}

.peepMenu {
    color: white;
    border: 2px solid white;
}

.eventsTittle {
    width: 80vw;
    margin-left: 10vw;
    z-index: 10;
}

.mapContainer {
    z-index: 10;
}

.happyHourMain {
    width: 100vw;
    margin-top: 15vh;
    display: flex;
    justify-content: center;
}

.happyHourMain div {
    width: 80vw;
    border: none;
}

@media only screen and (max-width: 1000px) {
    .Homepage-Container {
        height: auto;
    }
    .menu-page {
        padding: 0;
    }
    .slideshow-container {
        margin-top: 0;
    }
    .text {
        width: 80%;
    }
    .popover-menu-container {
        height: 3vh;
        background-color: #333;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        position: fixed;
        z-index: 100;
        width: 91vw;
    }
    .eventsMain img {
        width: 100%;
        height: 100%;
    }
    .eventsMain {
        justify-content: center;
        flex-direction: column-reverse;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    .eventsDiv {
        width: 80%;
    }
    .events-container h1 {
        margin-top: 10vh !important;
    }
    .happyHour {
        width: 80vw !important;
    }
    .entrees h2 {
        margin-top: 0;
    }
    .eventsTittle {
        width: 90vw;
        margin-left: 5vw;
    }
    .mapContainer {
        height: 30vh !important;
    }
    .happyHourMain{
        width: 100vw;
        margin-top: 5vh;
        display: flex;
        justify-content: center;
    }
}