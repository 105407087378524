.footerContainer {
    width: 100vw;
    display: flex;
    color: white;
    margin-top: 10vh;
    background-color: #333;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5vh;
}

.footerContainer a {
    text-decoration: none;
    color: white;
}

.mainfooter p {
    text-align: center;
    width: 50vw;
}

.mainfooter, .socialFooter {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding-bottom: 2vh;
    justify-content: space-evenly;
    align-items: center;
}

.socialFooter {
    justify-content: center;
    margin: 0;
    height: 2vh;
    margin-top: 0;
}

.socialFooter p {
    margin-left: 20px;
    margin-right: 20px;
}

@media only screen and (max-width: 1000px) {

    .footerContainer {
        align-items: center;
    }
}