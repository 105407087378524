
.events-container {
    margin: 0 auto;
    /* padding-top: 20vh; */
    height: min-content 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.events-container h1{
    margin-top: 20vh;
    text-align: center;
    font-size: 40px;
    /* font-family: 'Bebas Neue', sans-serif; */
    font-family: "Vast Shadow", serif;
    font-family: "Tourney", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "wdth" 100;
    font-weight: 400;
    color: white;
}

.events-grid {
    margin-left: 20vh;
    margin-right: 20vh;
}

.event-card {
    background-color: rgb(246, 188, 41);
    border-radius: 10px;
    border: 2px solid black;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    margin-bottom: 2vh;
}

.event-card:hover {
    transform: translateY(-5px);
}

.event-details {
    text-align: center;
}

.event-details h2 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1.8rem;
    color: black;
    font-family: 'Bebas Neue', sans-serif;
}

.event-details p {
    margin: 5px 0;
}

.event-details textarea {
    width: 100%;
    height: 100px;
    margin-top: 5px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.event-details label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.event-details input,
.event-details textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.event-details button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.event-details button:hover {
    background-color: #0056b3;
}

.form-container {
    max-width: 400px;
    margin: 20px auto;
}

.form-container h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
}

.form-container label {
    display: block;
    margin-bottom: 10px;
    color: white;
}

.form-container input,
.form-container textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: black;
}

.form-container button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.form-container button:hover {
    background-color: #0056b3;
}

@media only screen and (max-width: 1000px) {
    .event-card{
        width: 80vw;
    }
    .events-grid {
        margin-left: 5vw;
    }
}